import React, { Component } from 'react';

import '../assets/css/spinner.css';

class SpinnerLoading extends Component {
    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <div className="block"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
            </div>
        )
    }
}

export default SpinnerLoading;