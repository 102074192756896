import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "./context/auth";

function PublicRoute({ component: Component, ...rest }) {
	const isAuthenticated = useAuth();

	const toDashboard = () => {
		window.location.href = '/dashboard';
	}

	return (
		<Route
			{...rest}
            render={props => {
				return ! isAuthenticated ? (
					<Component setLoginState={(param) => rest.setLoginState(param)} {...props} />
				) : (
					toDashboard()
				)
            }}
		/>
	);
}

export default PublicRoute;