import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
	const isAuthenticated = useAuth();

	return (
		<Route
			{...rest}
			render={props => {
				return isAuthenticated ? (
					<Component setLoginState={(param) => rest.setLoginState(param)} {...props} />
				) : (
					<Redirect push to="/" />
				)
			}}
		/>
	);
}

export default PrivateRoute;