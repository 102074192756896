/*
* required polyfills
*/
import "core-js";
import 'core-js/features/set/map';
import { API_URL } from './constant/common';

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol'
// import 'core-js/es6/object'
// import 'core-js/es6/function'
// import 'core-js/es6/parse-int'
// import 'core-js/es6/parse-float'
// import 'core-js/es6/number'
// import 'core-js/es6/math'
// import 'core-js/es6/string'
// import 'core-js/es6/date'
// import 'core-js/es6/array'
// import 'core-js/es6/regexp'
// import 'core-js/es6/map'
// import 'core-js/es6/weak-map'
// import 'core-js/es6/set'
// import 'core-js/es7/object'

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect'

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect'

// CustomEvent() constructor functionality in IE9, IE10, IE11
(function () {

  if ( typeof window.CustomEvent === "function" ) return false

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent( 'CustomEvent' )
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})();

(function () {
  function toQUeryParam (data) {

    // If the data is already a string, return it as-is
    if (typeof (data) === 'string') return data;
  
    // Create a query array to hold the key/value pairs
    var query = [];
  
    // Loop through the data object
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        // Encode each key and value, concatenate them into a string, and push them to the array
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
      }
    }
  
    // Join each item in the array with a `&` and return the resulting string
    return query.join('&');
  };

  window.toQUeryParam = toQUeryParam;
})();

// custom fetch data
(function () {
  const token = localStorage.getItem("token");

  if (! token) return false;

  function fetchData (method, url, data) {
    let param = '';

    if (method.toUpperCase() === 'GET') {
      param = window.toQUeryParam(data);
    }

    return new Promise (function (resolve, reject) {
      fetch(API_URL + 'api/' + url + '?' + param, {
        method: method,
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: (function () {
          if (method.toUpperCase() === 'GET') {
            return null;
          }

          return data;
        })()
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        }
        else {
          throw {
            status: response.status,
            statusText: response.statusText
          };
        }
      }).then(function (response) {
        return resolve(response);
      }).catch(function (error) {
        return reject(error);
      })
    })
  }

  window.fetchData = fetchData;
})();