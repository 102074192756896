import React, { Component } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SpinnerLoading from './components/SpinnerLoading'
import './App.scss';

const loading = () => <SpinnerLoading/>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/auth/Login'));
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'));
const VerifyForgotPassword = React.lazy(() => import('./pages/auth/VerifyForgotPassword'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem('token') ? 1 : 0
    }

    this.setLoginState = this.setLoginState.bind(this);
  }

  setLoginState(param) {
    this.setState({
      isLoggedIn: param
    });
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  render() {
    return (
      <AuthContext.Provider value={this.state.isLoggedIn}>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <PublicRoute setLoginState={(param) => this.setLoginState(param)} exact path="/" component={Login} />
              <PublicRoute setLoginState={(param) => this.setLoginState(param)} exact path="/register" component={Register} />
              <PublicRoute setLoginState={(param) => this.setLoginState(param)} exact path="/forgot-password" component={ForgotPassword} />
              <PublicRoute setLoginState={(param) => this.setLoginState(param)} exact path="/verify-forgot-password" component={VerifyForgotPassword} />
              <PrivateRoute setLoginState={(param) => this.setLoginState(param)} path="/:menu" component={DefaultLayout} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            </Switch>
          </React.Suspense>
        </Router>
      </AuthContext.Provider>
    );
  }
}

export default App;